<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white;">
    <v-col sm="12" lg="5" class="tw-hidden left-side lg:tw-flex">

      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="7" class="right-side">
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/bluelogo.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 lg:mt-6 img-logo">

        <p class="welcome tw-mt-0">Company Info</p>
        <p class="admin tw-mt-2">
          We need some more information
        </p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveData)">
        <v-row class="upload-div">
          <v-col lg="6" sm="6">
            <p class="upload-div-text">Company Logo</p>
              <button class="button-logo-input" type="button" @click="onLogoFile" :class="image ? 'border-0' : ''">
                <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;" v-if="logoImageLoading"></v-progress-circular>
                <img :src="developerData.logo" alt="" class="button-logo-img" v-if="developerData.logo">
                <img :src="image" alt="" class="button-logo-img" v-else-if="logoImage">
                <img src="../assets/addImage.svg" alt="" class="button-logo-input-img" v-else>
              </button>
            <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
          </v-col>
            <v-col lg="6" sm="6">
                <p class="upload-div-text">CAC Certificate</p>
                <button class="button-logo-input" type="button" @click="onCacLogoFile" :class="cacImage ? 'border-0' : ''">
                    <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;" v-if="cacLogoImageLoading"></v-progress-circular>
                    <img :src="developerData.cacCertificate" alt="" class="button-logo-img" v-if="developerData.cacCertificate">
                    <img :src="cacImage" alt="" class="button-logo-img" v-else-if="cacLogoImage">
                    <img src="../assets/addImage.svg" alt="" class="button-logo-input-img" v-else>
                </button>
                <input type="file" ref="cacLogoImage" accept="image/*,.doc,.docx,.pdf" style="display: none" @change="onCacLogoImagePicked">
            </v-col>
        </v-row>
        <v-row class="tw-flex lg:tw-flex-row tw-flex-col">
          <v-col lg="6" sm="12"  class="mb-md-1">
             <ValidationProvider name="First Name" rules="required" v-slot="{ classes, errors }">
               <label  class="label">First Name</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-text-field  placeholder="Chioma" solo hide-details type="text" v-model="developerData.firstname"  required></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

          </v-col>

          <v-col lg="6" sm="12"  class="mb-md-1">
            <ValidationProvider name="Last name" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Last Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field  placeholder="Ekong" solo hide-details type="text" v-model="developerData.lastname"  required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="tw-flex lg:tw-flex-row tw-flex-col">
          <v-col lg="6" sm="12" >
            <ValidationProvider name="Company Name" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Company Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field  placeholder="RoadPadi" solo hide-details type="text" v-model="developerData.companyName"  required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
            <v-col lg="6" sm="12"  class="mb-md-1">
                <label  class="label">Phone  Number</label>
                <div class="tw-pt-1 mb-1 mt-2">
                <phone-number class="" :phone-number-exist="developerData.phoneNumber" @getNumber="getNumber" :readonly="true"  />
                </div>
            </v-col>

        </v-row>

        <v-row class="tw-flex lg:tw-flex-row tw-flex-col">

            <v-col lg="6" sm="12" >
                <ValidationProvider name="CAC Registration Number" rules="required" v-slot="{ classes, errors }">
                    <label  class="label">CAC Registration Number</label>
                    <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                        <v-text-field  placeholder="RC24153511-0001" solo hide-details type="text" v-model="developerData.cacRegistrationNumber"  required></v-text-field>
                        <span>{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </v-col>
            <v-col lg="6" sm="12" >
                <ValidationProvider name="Tax ID Number" rules="required" v-slot="{ classes, errors }">
                    <label  class="label">Tax ID Number</label>
                    <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                        <v-text-field  placeholder="241535119" solo hide-details type="Number" v-model="developerData.taxId"  required></v-text-field>
                        <span>{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </v-col>
        </v-row>
        <div class="btn-div">
          <button class="btn-div-text" type="submit">
            <span>Address</span>
            <img src="../assets/arrow_forward.svg" alt="" class="btn-div-img">  
          </button>
        </div>
          </form>
        </validation-observer>
      </v-col>

    </v-col>

  </div>
</template>

<script>
  import OnboardingLeft from '@/components/reuseables/OnboardingLeft'

  import { uploadToCloudinary} from "../services/api/APIService";
  import PhoneNumber from "@/components/reuseables/PhoneNumber.vue";
  import {mapGetters} from "vuex";

  export default {
    name : "OnboardingCompanyInfo",
    components: {
        PhoneNumber,
      OnboardingLeft,

    },
    data() {
      return {
        companyWebsite : "",
        visionStatement: "",
        color: '#1E5770',
        logoImage: '',
        cacLogoImage: '',
        logoImageUrl: '',
        cacLogoImageUrl: '',
        logoImageLoading: false,
        cacLogoImageLoading: false,
        recoveredImg: '',
        cacRecoveredImg: '',
        image: '',
        cacImage: "",
        dialog : false,
      }
    },
    computed: {
      formIsValid() {
        return this.developerData.logo  && this.developerData.developerFirstname
            && this.developerData.developerLastname && this.developerData.phone
            && this.developerData.companyName  && this.developerData.regNumber
      },
      preset() {
        return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      },
      data() {
        return JSON.parse(sessionStorage.getItem('data'))
      },
      ...mapGetters("developerData", ["developerData"]),
    },
    methods:{
      fillFields(){
        this.image = ""
        if (this.data) {
          this.companyWebsite = this.data.website
          this.recoveredImg = this.data.companyLogo
          this.color = this.data.companyColor
          this.visionStatement = this.data.visionStatement
        }
      },
      ChangeT(title){
        this.title = title;
        this.color = title
        this.dialog = false
      },
      onLogoFile() {
        this.$refs.logoImage.click();
      },
      onCacLogoFile() {
            this.$refs.cacLogoImage.click();
        },
      async onLogoImagePicked(event){
        const files = event.target.files[0]
        try {
          this.logoImageLoading = true
          const fileReader =  new FileReader()
          fileReader.addEventListener('load', async () => {
            this.developerData.logo = ""
            this.image = fileReader.result
            this.logoImage = files.name
            let newData = {
              file: this.image,
              upload_preset: this.preset
            }
            const response = await uploadToCloudinary(newData)
            this.developerData.logo = response.data.url
            console.log(this.developerData)
            this.logoImageLoading = false
          })
          fileReader.readAsDataURL(files)
        } catch (err) {
          console.log(err.response)
        }

      },
        async onCacLogoImagePicked(event){
            const files = event.target.files[0]
            try {
                this.cacLogoImageLoading = true
                const fileReader =  new FileReader()
                fileReader.addEventListener('load', async () => {
                    this.developerData.cacCertificate = ""
                    this.cacImage = fileReader.result
                    this.cacLogoImage = files.name
                    let newData = {
                        file: this.cacImage,
                        upload_preset: this.preset
                    }
                    const response = await uploadToCloudinary(newData)
                    this.developerData.cacCertificate = response.data.url
                    console.log(this.developerData)
                    this.cacLogoImageLoading = false
                })
                fileReader.readAsDataURL(files)
            } catch (err) {
                console.log(err.response)
            }

        },
      toggleModal(){
        this.dialog = !this.dialog
      },
      saveData() {
          sessionStorage.setItem('developerData', JSON.stringify(this.developerData));
          this.$router.replace({ name: 'CompanyAddress' })

      },
        getNumber(number) {
            this.developerData.phone = number;
        },
        async getDeveloperByUseId(){
          let userData = JSON.parse(sessionStorage.getItem("userData"))
            if (userData && Object.keys(userData).length){
              let data = {}
              data.email = userData.email
              await  this.$store.dispatch("developerData/setDeveloperData",data)
            }
        }
    },
    mounted(){

    },
    created() {
      let developerData = JSON.parse(sessionStorage.getItem("developerData"))
      if (developerData){
        this.developerData = developerData
      }
      else {
          this.getDeveloperByUseId()
      }
    }
  }
</script>

<style scoped lang="scss">
.v-progress-circular {
  margin: 1rem;
}
button:disabled,
button[disabled]{
  opacity: 0.7;
}

.left-side{
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}

.right-side-body{
  width: 100%;
  padding:7rem;
  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.3rem;
  }
}

.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .welcome {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1E5770;
;
}

.right-side-body .admin {
  width: auto;
  height: 24px;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;
  margin-top: 17px;
}

.upload-div {
  margin-top: 37px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}

.welcome{
  font-family: "Inter",sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  
  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}

.upload-div .upload-div-text {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
  margin-bottom: 20px;
}

.color-picker {
  height: 84px;
  width: 84px;
  padding: 20px 05px;
  // background: blue;
  border-radius: 100%;
  cursor: pointer;
}

.button-logo-input {
  display:flex;
  position: relative;
  border-radius: 100%;
  align-items: center;

}
.button-logo-input .button-logo-img {
  width: 147px;
  height: 121px;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: 147px;
  height: 121px;
}

.label {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}

.btn-div{
  display: flex;
  justify-content: flex-end;
  margin-top: 52px;
}

.btn-div .btn-div-text {
  border-radius: 10px;
  background: #1E5770;
  color: white;
  text-align: right;
  padding: 10px 30px;
  width: 161px;
  justify-content: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.btn-div .btn-div-text .btn-div-img {
  margin-left: 5px;
}

</style>